// extracted by mini-css-extract-plugin
export var postList = "postList-module--post-list--1lvaU";
export var postItem = "postList-module--post-item--RwSCk";
export var postTitle = "postList-module--post-title--1oKUA";
export var postLink = "postList-module--post-link--2kULN";
export var svg = "postList-module--svg--2J7Vv";
export var date = "postList-module--date--3Rvd9";
export var series = "postList-module--series--1TOHc";
export var tags = "postList-module--tags--1_paP";
export var description = "postList-module--description--2jMnd";
export var post = "postList-module--post--2maDi";
export var update = "postList-module--update--DS7rJ";